import 'highlight.js/styles/github.css';
import '../styles/markdown.css';

import classNames from 'classnames';
import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';
import php from 'highlight.js/lib/languages/php';
import shell from 'highlight.js/lib/languages/shell';
import xml from 'highlight.js/lib/languages/xml';
import { FC, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';

hljs.registerLanguage('xml', xml);
hljs.registerLanguage('json', json);
hljs.registerLanguage('shell', shell);
hljs.registerLanguage('php', php);

const wrap = (el, wrapper) => {
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
};

const Template: FC<{
  pageContext: { html: string; title: string; comment: string, keywords: string };
}> = ({ pageContext }) => {
  const containerRef = useRef();
  const { html, title, comment, keywords } = pageContext;

  useEffect(() => {
    const containerEl = containerRef.current;
    const elements = containerEl.querySelectorAll('pre code');

    elements.forEach(hljs.highlightElement);
  }, []);

  useEffect(() => {
    const containerEl = containerRef.current;
    const elements = containerEl.querySelectorAll('table');

    elements.forEach((element) => {
      const wrapper = document.createElement('div');
      wrapper.classList.add('overflow-x-auto');

      wrap(element, wrapper);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={comment} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <div
        ref={containerRef}
        className={classNames(
          'markdown',
          'container',
          'pt-[24px]',
          'sm:pt-[138px]',
          'pb-[24px]',
          'sm:pb-[85px]'
        )}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </>
  );
};

export default Template;
